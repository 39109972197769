<script lang="ts" setup>
const { t } = useT();
const isGuest = useIsGuest();

const { duration, durationLeft, buttonText, handleCardClick } = useAppPrizeDrops({
	immediate: false
});
</script>

<template>
	<ACard
		class="prize-drops"
		width="292px"
		height="380px"
		:imgAttrs="{
			width: 584,
			height: 760,
			src: '/nuxt-img/prize-drops/promo/bg.png',
			format: 'avif'
		}"
		alt="prize-drops-card"
		@click="handleCardClick"
	>
		<template #topLine>
			<AText class="countdown" :modifiers="['uppercase', 'center']" as="div">
				<template v-if="isGuest">{{ t("Register to Play") }}</template>
				<template v-else> {{ t("Ends in") }}&nbsp;{{ duration ? durationLeft : "00H 00M" }} </template>
			</AText>
		</template>
		<template #top>
			<NuxtImg
				src="/nuxt-img/prize-drops/promo/logo.png"
				width="531"
				height="300"
				format="avif"
				loading="lazy"
				class="logo"
			/>

			<div class="value">
				<AText as="div" variant="topeka" :modifiers="['semibold']" class="text">{{ t("Find up to:") }}</AText>
				<MPrizeFund variant="entries" is-svg icon="entries" :icon-size="24">
					<AText variant="tirana" class="text-cesena" :modifiers="['bold']">
						{{ t("prizeDrops.totalPrize") }}
					</AText>
				</MPrizeFund>
			</div>
		</template>

		<template #bottom>
			<AButton class="button" variant="primary" size="xl" data-tid="promo-play-prizes" @click.stop="handleCardClick">
				<AText variant="turin" :modifiers="['medium']">
					{{ buttonText }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.prize-drops {
	--a-card-default-top-gap: 16px;

	width: 292px;
	height: 380px;
}
.logo {
	width: 265px;
	height: 150px;
}

.countdown {
	position: relative;
	padding: 8px;
	width: calc(100% - 24px);
	height: 34px;
	background: rgba(var(--chattogram-rgb), 0.4);
	margin: 0 auto;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	z-index: 3;
}

.text {
	text-shadow:
		0 10px 15px rgba(2, 6, 23, 0.1),
		0 4px 6px rgba(2, 6, 23, 0.05);
}

.value {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;
}

.button {
	width: 250px;
}
</style>
